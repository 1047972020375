
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import ContactUs from '@/components/contactUs/index.vue'
import {config} from "@/utils"
export default {
    name: 'online',
    components:{
        Header,
        Footer,
        ContactUs
    },
    mounted () {
    },
    created(){

    },

    methods: {
        contactKefu(){
            // source(来源) 1:人是云    2:识权识美
            if(localStorage.getItem('_RedirectData') !=null){
                let ac=JSON.parse(localStorage.getItem('_RedirectData'))
                let url=`${config.kefu}/frontend?source=2`+'&token='+ac.token+'&usertype='+ac.usertype+'&chat_role=1'
                window.open(url)
            }else{
                let url=`${config.kefu}/frontend?source=2`+'&chat_role=1'
                window.open(url)
            }


        },

        preloadImage(src) {
            return new Promise((resolve) => {
              const img = new Image();
              img.onload = () => resolve(src);
              img.src = src;
            });
          },
          async changeBackground() {
            // 预加载新的背景图片
            await this.preloadImage(this.serviceBgList[this.serviceActive]);

            // 设置新的背景图片
            this.backgroundImage = this.serviceBgList[this.serviceActive];
          },
    },

    data(){
        return{
            serviceList:[
                {
                    title:'工商服务',
                    desc:'企业工商注册、企业变更备案、企业基础账户管理服务、代理记账、场地租赁、企业年度报告编制',
                    bot:'进驻申请'
                },
                {
                    title:'专利咨询',
                    desc:'商标注册申请、计算机软件著作权申请、发明专利申请、实用新型专利申请、外观设计专利申请、专利维护与年费管理',
                    bot:'进驻申请'
                },
                {
                    title:'创业补贴',
                    desc:'国家创业扶持，中小企业创新基金、贷款贴息、创业带动就业补贴、创业租金补贴、就业创业补贴、高新企业认定、科技型企业认定',
                    bot:'进驻申请'
                },
                {
                    title:'资质申请',
                    desc:'行业经营许可证、专业资质认证、iso体系认证、产品认证、行业特定资质（建筑、医药、食品等）、其他行政许可（环保许可证、消防安全检查等）',
                    bot:'进驻申请'
                },
                {
                    title:'制修定标准体系',
                    desc:'国家标准体系、行业标准体系、地方标准体系、团体标准体系、企业标准体系、产品标准体系',
                    bot:'进驻申请'
                },


            ],
            serviceActive: 0,
            serviceBgList:[
                require('./images/serviceBg1.png'),
                require('./images/serviceBg2.png'),
                require('./images/serviceBg3.png'),
                require('./images/serviceBg4.png'),
                require('./images/serviceBg5.png'),
            ],
            serviceItemList:[
                {
                    img1:require('./images/service1.png'),
                    img2: require('./images/serviceAct1.png'),
                    title:'工商服务',
                },
                {
                    img1:require('./images/service2.png'),
                    img2: require('./images/serviceAct2.png'),
                    title:'专利咨询',
                },
                {
                    img1:require('./images/service3.png'),
                    img2: require('./images/serviceAct3.png'),
                    title:'创业补贴',
                },
                {
                    img1:require('./images/service4.png'),
                    img2: require('./images/serviceAct4.png'),
                    title:'资质申请',
                },
                {
                    img1:require('./images/service5.png'),
                    img2: require('./images/serviceAct5.png'),
                    title:'制修定标准体系',
                },


            ],

            consultActive: 0,
            consultInfoList: [
                {
                    itemList: [
                        {img:require('./images/consult1-1.png'),title:'技术难题对接'},
                        {img:require('./images/consult1-2.png'),title:'技术中试'},
                        {img:require('./images/consult1-3.png'),title:'资产评估'},
                    ],
                    img: require('./images/consult1.png'),
                    color: '#5890FF',
                    bot:'立即咨询',
                },
                {
                    itemList: [
                        {img:require('./images/consult2-1.png'),title:'产学研合作'},
                        {img:require('./images/consult2-2.png'),title:'共建创新平台'},
                        {img:require('./images/consult2-3.png'),title:'技术难题攻关'},
                    ],
                    img: require('./images/consult2.png'),
                    color: '#1ABC9C',
                    bot:'立即咨询',
                },
                {
                    itemList: [
                        {img:require('./images/consult3-1.png'),title:'政策匹配'},
                        {img:require('./images/consult3-2.png'),title:'高企测评'},
                        {img:require('./images/consult3-3.png'),title:'政策咨询'},
                    ],
                    img: require('./images/consult3.png'),
                    color: '#08B8CB',
                    bot:'立即咨询',
                },
                {
                    itemList: [
                        {img:require('./images/consult4-1.png'),title:'商业计划书'},
                        {img:require('./images/consult4-2.png'),title:'精选资金'},
                        {img:require('./images/consult4-3.png'),title:'科技保险'},
                    ],
                    img: require('./images/consult4.png'),
                    color: '#FF8026',
                    bot:'立即咨询',
                }
            ],

            backgroundImage: require('./images/serviceBg1.png'),


        }

    }

}